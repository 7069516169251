import React, { FC } from 'react';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';

interface LinkToProps {
  to?: string;
  onClick?: any;
  className?: string;
  state?: any;
  id?: string;
}

const StyledA = styled.a`
  ${({ theme: { typography } }) => css`
    ${typography.a.default};
  `};
`;

export const LinkTo: FC<React.PropsWithChildren<LinkToProps>> = ({
  to,
  children,
  onClick,
  state,
  id,
  className = '',
  ...rest
}) => {
  const domain = process.env.GATSBY_FRONTEND_URL;

  let link = to;
  let isExternal = to?.trim().startsWith('http');
  const isAnchor = to?.trim().startsWith('#');
  const isMail = to?.trim().startsWith('mailto');
  const isTel = to?.trim().startsWith('tel');

  if (isExternal && domain && link.includes(domain)) {
    [, link] = to.split(domain);
    isExternal = false;
  }

  return (
    <>
      {isExternal || isAnchor || isMail || isTel ? (
        // eslint-disable-next-line react/jsx-no-target-blank
        <StyledA
          href={link}
          target={isExternal ? '_blank' : ''}
          rel={isExternal ? 'noopener noreferrer' : ''}
          className={`${isExternal ? 'external' : ''} ${className}`}
          onClick={onClick}
          id={id}
          {...rest}
        >
          {children}
        </StyledA>
      ) : (
        <Link
          to={link}
          id={id}
          activeClassName="is-active"
          className={`${isExternal ? 'external' : ''} ${className}`}
          onClick={onClick}
          state={state}
          {...rest}
        >
          {children}
        </Link>
      )}
    </>
  );
};

export default LinkTo;
