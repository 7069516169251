import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { Container } from './Container';

export interface SectionProps {
  background?: 'default' | 'white' | 'grey' | 'darkBlue';
  mobileNoPadding?: boolean;
}

type StyledSectionProps = Pick<SectionProps, 'background'>;

const StyledSection = styled.div<StyledSectionProps>`
  ${({ theme: { space, colors, media, components }, background }) => css`
    padding: ${space.xxl} 0;
    max-width: 100vw;
    overflow-x: clip;

    // Background color


    ${
      background === 'default' &&
      css`
        background-color: ${components.section.defaultBackground};
      `
    }

    ${
      background === 'white' &&
      css`
        background-color: ${colors.white.default};
      `
    }

    ${
      background === 'grey' &&
      css`
        background-color: ${colors.grey.default};
      `
    }

    ${
      background === 'darkBlue' &&
      css`
        background-color: ${colors.darkPrimary.default};
      `
    }


    // Spacing between sibling sections

    &.section--${background} +  .section--${background} {
      padding-top: 0;
    }

    &.section--${background} + div > .section--${background}:first-of-type {
      padding-top: 0;
    }

    // Desktop styles

    @media (min-width: ${media.xl}) {
      padding: ${space.xxxxl} 0;
  `};
`;

export const Section: FC<React.PropsWithChildren<SectionProps>> = ({
  children,
  background = 'default',
  mobileNoPadding,
  ...rest
}) => (
  <StyledSection background={background} className={`section--${background}`} {...rest}>
    <Container mobileNoPadding={mobileNoPadding}>{children}</Container>
  </StyledSection>
);
