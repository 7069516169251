export const colorPalette = {
  white: '#ffffff',
  darkBlue: '#02315B',
  red: '#E30613',
  green: '#228300',
  lightGreen: '#1b6800',
  lightGrey: '#E6EAEF',
  midGrey: '#849AAF',
  blue: '#02315B',
  transparentGreen: '#22830052',
};

// Then reference the colors in your components object like this:

export const components = {
  // Button

  // Global

  global: {
    focusColor: colorPalette.darkBlue,
    invalid: colorPalette.red,
    valid: colorPalette.green,
  },

  // Component colours (for theming)

  header: {
    background: colorPalette.white,
    textColor: colorPalette.darkBlue,
    textTransform: 'none',
  },

  footer: {
    background: colorPalette.darkBlue,
    textColor: colorPalette.white,
  },

  section: {
    defaultBackground: colorPalette.lightGrey,
  },

  pageHeader: {
    background: colorPalette.lightGrey,
    textColor: colorPalette.darkBlue,
  },

  tabs: {
    background: colorPalette.darkBlue,
    textColor: colorPalette.white,
    borderColor: colorPalette.darkBlue,
    borderRadius: '0px',
    containerBackground: colorPalette.white,
    activeBackground: colorPalette.white,
  },

  text: {
    defaultColor: colorPalette.darkBlue,
  },

  // Form steps

  formStep: {
    buttonColor: colorPalette.darkBlue,
    line: `url("data:image/svg+xml,%3Csvg width='2' height='1472' viewBox='0 0 2 1472' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23${colorPalette.blue.replace(
      '#',
      ''
    )}' stroke-width='2' stroke-linecap='round' stroke-dasharray='2 4' d='M1 1v1470'/%3E%3C/svg%3E");`,
    circle: {
      background: colorPalette.darkBlue,
      textColor: colorPalette.white,
    },
  },

  // Panel

  panel: {
    defaultBackground: colorPalette.lightGrey,
    headerBackground: colorPalette.darkBlue,
    headerTextColor: colorPalette.white,
  },

  // Form field panel

  formFieldPanel: {
    background: colorPalette.lightGrey,
    borderRadius: '4px',
  },

  // Info

  info: {
    background: colorPalette.lightGrey,
    border: 'none',
    borderRadius: '4px',
    flexDirection: 'row',
  },

  // Form base

  formBase: {
    hoverShadow: `${colorPalette.darkBlue} 0px 0px 0px 1px`,
    valid: `2px solid ${colorPalette.green}`,
    borderColor: colorPalette.darkBlue,
    invalid: colorPalette.red,
    borderRadius: '4px',
    selectArrow: `url("data:image/svg+xml,%3Csvg width='18' height='10' viewBox='0 0 18 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.256 1a.727.727 0 0 0-.506.223L8.998 6.976l-6.75-5.753A.727.727 0 1 0 1.22 2.251l7.265 6.266a.726.726 0 0 0 1.027 0l7.265-6.266a.727.727 0 0 0-.52-1.25Z' fill='%23005AA0' stroke='%23005AA0'/%3E%3C/svg%3E")`,
  },

  // Filter input

  filterInput: {
    hoverBackground: colorPalette.lightGrey,
    hoverColor: colorPalette.darkBlue,
    hoverTextDecoration: 'none',
  },

  // Button select

  buttonSelect: {
    hasIcon: false,
    selectedBackground: colorPalette.darkBlue,
  },

  // Edit panel

  editPanel: {
    background: colorPalette.lightGrey,
  },

  // File input
  fileUpload: {
    borderColor: colorPalette.midGrey,
  },

  // Loader

  loader: {
    color: colorPalette.blue,
  },

  // Buttons

  buttons: {
    solid: {
      border: colorPalette.green,
      background: colorPalette.green,
      color: colorPalette.white,
      uppercase: false,
      underline: false,
      hover: {
        border: colorPalette.lightGreen,
        background: colorPalette.lightGreen,
        color: colorPalette.white,
        underline: false,
      },
      disabled: {
        border: colorPalette.transparentGreen,
        background: colorPalette.transparentGreen,
        color: colorPalette.white,
      },
    },
    outline: {
      border: colorPalette.darkBlue,
      background: colorPalette.white,
      color: colorPalette.darkBlue,
      uppercase: false,
      underline: false,
      hover: {
        border: colorPalette.darkBlue,
        background: colorPalette.lightGrey,
        color: colorPalette.darkBlue,
        underline: false,
      },
    },
  },

  // Checkbox

  checkbox: {
    border: colorPalette.darkBlue,
  },
};

export default components;

export type ThemeComponents = typeof components;
export type ThemeComponentsKeys = keyof typeof components;
