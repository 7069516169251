import { ThemeFontsKeys } from '../styles/tokens/typography';
import { typography, media } from '../styles/tokens';

export const getResponsiveType = (variant: ThemeFontsKeys) => {
  if (!variant) {
    return;
  }

  const generateTypeStyles = (version) => {
    const versionStyles = typography[variant][version];

    if (!versionStyles) {
      return;
    }

    let styles = `
      ${versionStyles.fontFamily ? `font-family: ${versionStyles.fontFamily};` : ''}
      ${versionStyles.fontWeight ? `font-weight: ${versionStyles.fontWeight};` : ''}
      ${versionStyles.fontSize ? `font-size: ${versionStyles.fontSize};` : ''}
      ${versionStyles.lineHeight ? `line-height: ${versionStyles.lineHeight};` : ''}
      ${versionStyles.textTransform ? `text-transform: ${versionStyles.textTransform};` : ''} 
      ${versionStyles.letterSpacing ? `letter-spacing: ${versionStyles.letterSpacing};` : ''} 
    `;

    if (versionStyles.breakpoints && versionStyles?.breakpoints.length > 0) {
      versionStyles.breakpoints.forEach((breakpoint) => {
        const { breakpointSize, fontSize, lineHeight, letterSpacing } = breakpoint;

        styles += `
        @media (min-width: ${media[breakpointSize]}) {
          ${fontSize ? `font-size: ${fontSize};` : ''}
          ${lineHeight ? `line-height: ${lineHeight};` : ''}
          ${letterSpacing ? `letter-spacing: ${letterSpacing};` : ''}
        }
      `;
      });
    }
    return styles;
  };

  const typeStyles = `
    ${generateTypeStyles('default')};
  `;

  return typeStyles;
};
