import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { Container } from './Container';
import { LinkTo } from './LinkTo';
import { Phone } from './icons/Phone';
import { Trustpilot } from './TrustPilot';
import { Typography } from './Typography';
import { PHONE_NUMBER, HOME_LINK, HOME_LABEL } from '../const';
import { useDataLayerPush } from '../hooks/useDataLayerPush';
import { Logo } from './Logo';
import { useThemeOptions } from '../hooks/useThemeOptions';

interface HeaderProps {}

// Styles

const StyledHeader = styled.header`
  ${({ theme: { space, components, media } }) => css`
    // Desktop styles
    padding: ${space.md} 0;
    background-color: ${components.header.background};
    color: ${components.header.textColor};

    @media (min-width: ${media.lg}) {
      padding: ${space.xl} 0;
    }
  `};
`;

const StyledContainer = styled(Container)`
  ${() => css`
    // Desktop styles
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
  `};
`;

const StyledPhoneLink = styled(LinkTo)`
  ${({ theme: { media, space } }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: ${space.xs};

    svg {
      width: 32px;
      height: 32px;
    }

    @media (min-width: ${media.lg}) {
      order: 3;
    }
  `};
`;

const StyledPhoneIcon = styled(Phone)`
  ${({ theme: { components } }) => css`
    color: ${components.header.textColor};
  `};
`;

const StyledCallTxt = styled(Typography)`
  ${({ theme: { maxMedia, components } }) => css`
    text-transform: ${components.header.textTransform};
    @media (max-width: ${maxMedia.lg}) {
      display: none;
    }
  `};
`;

const StyledLogo = styled.div`
  ${({ theme: { media } }) => css`
    @media (min-width: ${media.lg}) {
      order: 1;
    }
  `};
`;

const StyledTrustpilot = styled.div`
  ${({ theme: { media, space, maxMedia } }) => css`
    svg {
      margin: 0 auto;
    }
    @media (min-width: ${media.lg}) {
      order: 2;
      flex: auto;
    }
    @media (max-width: ${maxMedia.lg}) {
      flex: 0 0 100%;
      padding-bottom: ${space.sm};
    }
  `};
`;

export const Header: FC<HeaderProps> = () => {
  const { dataLayerPush } = useDataLayerPush();
  // number without any spaces
  const clickToCallNumber = PHONE_NUMBER.replace(/\s/g, '');
  const { enableTrustpilot } = useThemeOptions();

  return (
    <StyledHeader>
      <StyledContainer>
        {enableTrustpilot && (
          <StyledTrustpilot>
            <Trustpilot />
          </StyledTrustpilot>
        )}
        <a href={HOME_LINK} aria-label={HOME_LABEL}>
          <StyledLogo>
            <Logo />
          </StyledLogo>
        </a>
        <StyledPhoneLink
          aria-label="Call us"
          id="headerPhoneLink"
          to={`tel:${clickToCallNumber}`}
          onClick={() =>
            dataLayerPush({
              event: 'click_phone_number',
              element_url: `tel:${clickToCallNumber}`,
              element_id: 'headerPhoneLink',
              element_text: `Call us on ${PHONE_NUMBER}`,
            })
          }
        >
          <StyledPhoneIcon />
          <StyledCallTxt component="span" variant="h4">
            Call us on {PHONE_NUMBER}
          </StyledCallTxt>
        </StyledPhoneLink>
      </StyledContainer>
    </StyledHeader>
  );
};
