export const COMPANY_NAME = 'Agria Insurance';
export const SPECIES_ID = '636b067c-dd3e-480d-87e7-d59df08c221c';
export const DOG_SPECIES_ID = 'f4372701-68d2-4459-bdba-b3be753e80d4';
export const CAT_SPECIES_ID = 'c2597178-071f-4940-825d-4d7351b5ffa4';
export const RABBIT_SPECIES_ID = '717c7e3d-09cc-4e66-807a-3527541e51d1';
export const EQUINE_SPECIES_ID = '636b067c-dd3e-480d-87e7-d59df08c221c';
export const PHONE_NUMBER = '03330 30 83 48';
export const CS_PHONE_NUMBER = '03330 30 10 00';
export const FAQ_LINK = 'https://www.agriapet.co.uk/how-to-claim/';
export const MY_ACCOUNT_LOGIN_URL = 'https://www.agriapet.co.uk/my-account/login/';
export const HOME_LINK = 'https://www.agriapet.co.uk/';
