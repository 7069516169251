import { graphql, useStaticQuery } from 'gatsby';
import React, { useRef, FC } from 'react';
import { useThemeOptions } from '../hooks/useThemeOptions';
import { isBrowser } from '../utils/isBrowser';

interface TrustpilotProps {
  tag?: string;
}

interface TrustpilotWindow extends Window {
  Trustpilot: {
    loadFromElement: (HTMLElement, boolean) => any;
  };
}

declare const window: TrustpilotWindow;

export const Trustpilot: FC<Omit<TrustpilotProps, '__typename'>> = ({ tag }) => {
  const ref = useRef(null);
  React.useEffect(() => {
    if (isBrowser && window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  const { enableTrustpilot } = useThemeOptions();
  if (!enableTrustpilot) {
    return null;
  }

  return (
    <div
      ref={ref}
      className="trustpilot-widget"
      data-locale="en-GB"
      data-template-id="5419b6ffb0d04a076446a9af"
      data-businessunit-id="57f255330000ff0005958407"
      data-style-height="20px"
      data-style-width="100%"
      data-theme="light"
      data-text-color="#02315B"
      data-tags={tag || ''}
    >
      <a href="https://uk.trustpilot.com/review/agriapet.co.uk" target="_blank" rel="noopener noreferrer">
        Trustpilot
      </a>
    </div>
  );
};
