import queryString from 'query-string';
import { isBrowser } from './isBrowser';

export const getQueryString = (search?: string): Record<string, any> | undefined =>
  search ? queryString.parse(search) : undefined;

export const createQueryString = (query: Record<string, any>) => queryString.stringify(query);

export const replaceUrlParam = (searchParams) => {
  if (isBrowser && window.history.pushState) {
    const newurl = `${window.location.protocol}//${window.location.host}${
      window.location.pathname
    }?${searchParams.toString()}`;
    window.history.pushState({ path: newurl }, '', newurl);
  }
};
