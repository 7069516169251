import React, { FC } from 'react';

export const Phone: FC = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
    <path
      fill="currentColor"
      d="M23.053 28.8c-.613 0-1.226-.12-1.84-.36a31.46 31.46 0 0 1-10.653-7.013 31.536 31.536 0 0 1-7.013-10.64c-1-2.493.16-5.28 2.64-6.333 1.04-.44 2.133-.827 3.253-1.147A2.199 2.199 0 0 1 12 4.36l2.067 3.8c.466.854.306 1.92-.373 2.614L11.8 12.667a24.837 24.837 0 0 0 3.333 4.2c1.227 1.24 2.64 2.347 4.2 3.333l1.894-1.893c.68-.68 1.76-.84 2.6-.373L27.64 20c.92.494 1.347 1.547 1.053 2.56a28.402 28.402 0 0 1-1.146 3.254c-.8 1.88-2.587 3-4.494 3V28.8ZM10.08 4.947s-.093 0-.146.027c-1.054.306-2.08.666-3.054 1.08-1.6.68-2.36 2.48-1.72 4.093a30.06 30.06 0 0 0 6.627 10.067 29.6 29.6 0 0 0 10.066 6.626 3.157 3.157 0 0 0 4.094-1.706c.413-.96.773-1.987 1.08-3.054a.48.48 0 0 0-.213-.56L23 19.454c-.186-.107-.4-.067-.56.08L19.573 22.4 19 22.054a27.096 27.096 0 0 1-5.107-3.947c-1.48-1.48-2.8-3.2-3.946-5.107l-.347-.586 2.867-2.867a.484.484 0 0 0 .08-.56l-2.067-3.8a.47.47 0 0 0-.413-.24h.013Z"
    />
  </svg>
);
