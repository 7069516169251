const iconSizes = {
  default: '32 32',
  extraSmall: '12 12',
  small: '16 16',
  medium: '32 32',
  large: '48 48',
  extraLarge: '56 56',
};

export default iconSizes;
export type ThemeIconSizes = typeof iconSizes;
export type ThemeIconSizesKeys = keyof typeof iconSizes;
