import React from 'react';
import { Script, ScriptStrategy } from 'gatsby';

export const ScriptsAfter = () => (
  <>
    {/* Trustpilot */}
    <Script
      strategy={ScriptStrategy.idle}
      type="text/javascript"
      src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
    />
  </>
);
