/* eslint-disable no-undef */
import React, { createContext, eRef, useState } from 'react';

interface GaProps {
  path?: string;
  stage?: string;
  step?: string;
}

interface GaContextProps {
  routeData?: GaProps;
  setRouteData: React.Dispatch<React.SetStateAction<GaProps | undefined>>;
}
export const GaContext = createContext({} as GaContextProps);
GaContext.displayName = 'GaContext';

export const GaContextProvider = ({ children }) => {
  const [routeData, setRouteData] = useState<GaProps>();

  const contextValues: GaContextProps = {
    routeData,
    setRouteData,
  };

  return <GaContext.Provider value={contextValues}>{children}</GaContext.Provider>;
};
