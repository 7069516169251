import { colors } from './colors';

const borders = {
  default: `solid 1px ${colors.primary.default}`,
  green: `solid 1px ${colors.green.default}`,
  lightGreen: `solid 1px ${colors.lightGreen.default}`,
  blue: `solid 1px ${colors.primary.default}`,
  red: `solid 1px ${colors.red.default}`,
};

export default borders;

export type ThemeBorders = typeof borders;
export type ThemeBordersKeys = keyof typeof borders;
