import React, { FC, useEffect, useRef } from 'react';
import { useDataLayerPush } from '../hooks/useDataLayerPush';

export interface DataLayerWrapperProps {
  children: any;
  data: any;
  serverData: any;
  location: any;
}

export const DataLayerWrapper: FC<DataLayerWrapperProps> = ({ children, location }) => {
  const { dataLayerPush } = useDataLayerPush();

  const previousPath = useRef<string>();

  // Function to remove `policyNumber` parameter
  const stripPolicyNumber = (url: string) => {
    try {
      const parsedUrl = new URL(url);
      parsedUrl.searchParams.delete('policyNumber');
      return parsedUrl.toString();
    } catch {
      // Return the original URL if parsing fails
      return url;
    }
  };

  // Function to determine content type based on subdomain
  const getContentType = (url: string) => {
    try {
      const parsedUrl = new URL(url);
      const subdomain = parsedUrl.hostname.split('.')[0]; // Extract the subdomain
      return subdomain.includes('vet') ? 'online claims - vet' : 'online claims - customer';
    } catch {
      // Fallback if URL parsing fails
      return 'online claims - customer';
    }
  };

  useEffect(() => {
    if (!location?.host) return;

    // Get referrer from localStorage or fallback to document.referrer
    const storedReferrer = localStorage.getItem('lastPage');
    const pageReferrer = stripPolicyNumber(storedReferrer || previousPath.current || document.referrer);

    // Store current page for next navigation
    localStorage.setItem('lastPage', location.href);

    const pageData = {
      event: 'virtual_pageview',
      spa_page_url: stripPolicyNumber(location?.href),
      spa_page_referrer: pageReferrer,
      content_group: 'online claims',
      content_type: getContentType(location?.href), // Dynamically set content type
    };

    // Push constructed data to data layer
    dataLayerPush({
      ...pageData,
    });

    // Update previous path ref for next navigation
    previousPath.current = location?.href;

    // Clean up only when leaving the site entirely
    const handleUnload = () => {
      localStorage.removeItem('lastPage');
    };

    window.addEventListener('beforeunload', handleUnload);
    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, [location, dataLayerPush]);

  return <>{children}</>;
};
