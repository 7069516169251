import React, { createContext, useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { RefetchOptions, RefetchQueryFilters, QueryObserverResult } from 'react-query';
import { isBrowser } from '../utils/isBrowser';
import { getQueryString } from '../utils/queryStrings';
import { Quote } from '../types/customer';
import { useGetQuoteEquine } from '../hooks/useGetQuoteEquine';
import { useGetBasketEquine } from '../hooks/useGetBasketEquine';
import { BasketEquine } from '../types/quote';

interface CustomerContextProps {
  campaignId?: string;
  affiliateId?: string;
  sskey?: string;
  utmSource?: string;
  quote?: Partial<Quote>;
  setQuote: React.Dispatch<React.SetStateAction<Partial<Quote> | undefined>>;
  basket?: Partial<BasketEquine> | undefined;
  refetchBasket: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<BasketEquine, unknown>>;
}

export const CustomerContext = createContext({} as CustomerContextProps);
CustomerContext.displayName = 'CustomerContext';

export function CustomerContextProvider({ children, location }) {
  const qs = getQueryString(location?.search);

  const { data: quoteData, fetchQuote } = useGetQuoteEquine();
  const { data: basketData, fetchBasket, refetch: refetchBasket } = useGetBasketEquine();

  // Logic to get and save campaign ID
  const [campaignId, setCampaignId] = useState<string>();

  // Logic to get and save Affiliate ID
  const [affiliateId, setAffiliateId] = useState<string>();

  // Logic to get and save SS KEY - for Optimise
  const [sskey, setSskey] = useState<string>();

  // Logic to get and save UTM source - for Optimise
  const [utmSource, setUtmSource] = useState<string>();

  // Stores the currentActive quote ID
  const [quoteReferenceNumber, setQuoteReferenceNumber] = useState<string>();

  const [postcode, setPostcode] = useState<string>();

  // Stores the current quote, quotes only contain one pet
  const [quote, setQuote] = useState<Partial<Quote>>();

  // Stores the current basket, cntains multiple qu if completedotes or policies
  const [basket, setBasket] = useState<Partial<BasketEquine>>();

  // Stored basket and quote
  const storedBasketId = isBrowser ? (window.sessionStorage.getItem('basketId') as string) : undefined;
  const storedQuoteReferenceNumber = isBrowser
    ? (window.sessionStorage.getItem('quoteReferenceNumber') as string)
    : undefined;
  const storedPostcode = isBrowser ? (window.sessionStorage.getItem('postcode') as string) : undefined;

  // Handle getting/storing quote ref ID for query string

  useEffect(() => {
    if (qs?.quoteref && qs.postcode) {
      setQuoteReferenceNumber(qs?.quoteref);
      setPostcode(qs.postcode);

      if (isBrowser) {
        window.sessionStorage.setItem('quoteReferenceNumber', qs?.quoteref);
        window.sessionStorage.setItem('postcode', qs?.postcode);
      }
    }
    if (!qs?.quoteref && isBrowser && storedQuoteReferenceNumber) {
      setQuoteReferenceNumber(storedQuoteReferenceNumber);
    }
    if (!qs?.postCcde && isBrowser && storedPostcode) {
      setPostcode(storedPostcode);
    }
    if (!qs?.quoteref && isBrowser && storedBasketId) {
      fetchBasket(storedBasketId);
    }
  }, [qs]);

  useEffect(() => {
    const url = typeof window !== 'undefined' ? window.location.href : '';
    if (quoteReferenceNumber && postcode && !url.includes('confirmation')) {
      fetchQuote(quoteReferenceNumber, postcode);
    }
  }, [quoteReferenceNumber, postcode]);

  useEffect(() => {
    if (quoteData) {
      if (quoteData?.BasketId) {
        fetchBasket(quoteData.BasketId);
      }
      if (isBrowser) {
        window.sessionStorage.setItem('basketId', quoteData.BasketId);
      }
      setQuote(quoteData);
    }
  }, [quoteData]);

  useEffect(() => {
    if (basketData) {
      setBasket(basketData);
      if (quoteReferenceNumber) {
        const currentQuote = basketData.Quotes.find((quote) => quote.QuoteReference === quoteReferenceNumber);
        setQuote(currentQuote);
      }
    }
  }, [basketData]);

  // Handle Cam ID

  useEffect(() => {
    // Cam ID
    if (qs?.camid) {
      setCampaignId(qs.camid);
      if (isBrowser) {
        window.sessionStorage.setItem('camid', qs.camid);
      }
    } else if (isBrowser && window.sessionStorage.getItem('camid')) {
      const camidFromStorage = window.sessionStorage.getItem('camid');
      setCampaignId(camidFromStorage as string);
      if (quote) {
        setQuote({ ...quote, CampaignId: campaignId });
      } else {
        setQuote({ CampaignId: campaignId });
      }
    }
    // affid ID
    if (qs?.affid) {
      setAffiliateId(qs.affid);
      if (isBrowser) {
        sessionStorage.setItem('affid', qs.affid);
      }
    } else if (isBrowser && window.sessionStorage.getItem('affid')) {
      const affidFromStorage = window.sessionStorage.getItem('affid');
      setAffiliateId(affidFromStorage as string);
      if (quote) {
        setQuote({ ...quote, AffiliateId: affiliateId });
      } else {
        setQuote({ AffiliateId: affiliateId });
      }
    }
    // SS key
    if (qs?.sskey) {
      setSskey(qs?.sskey);
      if (isBrowser) {
        sessionStorage.setItem('sskey', qs?.sskey);
      }
    } else if (isBrowser && window.sessionStorage.getItem('sskey')) {
      const sskeyFromStorage = window.sessionStorage.getItem('sskey');
      setSskey(sskeyFromStorage as string);
    }
    // Utm source
    if (qs?.utm_source) {
      setUtmSource(qs?.utm_source);
      if (isBrowser) {
        sessionStorage.setItem('utmSource', qs?.utm_source);
      }
    } else if (isBrowser && window.sessionStorage.getItem('utmSource')) {
      const utmSourceFromStorage = window.sessionStorage.getItem('utmSource');
      setUtmSource(utmSourceFromStorage as string);
    }
  }, [qs]);

  const contextValues: CustomerContextProps = {
    campaignId,
    affiliateId,
    sskey,
    utmSource,
    quote,
    setQuote,
    basket: basketData,
    refetchBasket,
  };

  return <CustomerContext.Provider value={contextValues}>{children}</CustomerContext.Provider>;
}
