import React from 'react';
import { BaseLayout } from './src/layouts/Base';
import { ScriptsBefore } from './src/components/ScriptsBefore';
import { ScriptsAfter } from './src/components/ScriptsAfter';

export const wrapPageElement = ({ element, props }) => (
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  <BaseLayout {...props}>
    <>
      <ScriptsBefore />
      {/* Element */}
      {element}
      <ScriptsAfter />
    </>
  </BaseLayout>
);
