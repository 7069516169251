import { useState } from 'react';
import { useQuery } from 'react-query';
import { service } from '../client';
import { ApiResponse } from '../types/apis';
import { BasketEquine } from '../types/quote';
import { dataOrError } from '../utils/dataOrError';

interface GetBasketEquineRequest {
  basketId: string;
  // basketReferenceNumber: string;
  // postcode: string;
}
const getBasketEquines = async ({ basketId }: GetBasketEquineRequest) => {

  const { data } = await service.get<ApiResponse<BasketEquine>>(
    `/Quote/GetBasketEquine?basketId=${basketId}&basketReferenceNumber&postcode`
  );
  return dataOrError<BasketEquine>(data);
};

export function useGetBasketEquine() {
  const [basketId, setBasketId] = useState('');
  const res = useQuery(['getBasket', basketId], () => getBasketEquines({ basketId }), {
    enabled: !!basketId,
  });

  const fetchBasket = (newBasketId) => {

    if(newBasketId) {
      setBasketId(newBasketId);

    }
  };

  return {
    ...res,
    fetchBasket,
  };
}
