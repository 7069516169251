import React, { createContext, useEffect, useState, useMemo, Dispatch, SetStateAction } from 'react';
import { ClaimForm, ClaimPetDetails } from '../types/claim';
import { isBrowser } from '../utils/isBrowser';

type Claim = {
  ClaimDate: string;
  ClaimDescription: string;
  ClaimReferenceNumber: string;
  ClaimStatus: string;
  Id: string;
};

type PolicyDates = {
  CoverStartDate: string;
  CoverEndDate: string;
};

interface ClaimContextProps {
  claim?: Partial<ClaimForm>;
  setClaim: Dispatch<SetStateAction<Partial<ClaimForm> | undefined>>;
  petDetails?: ClaimPetDetails;
  setPetDetails: Dispatch<SetStateAction<ClaimPetDetails | undefined>>;
  claimQueueId?: string;
  setClaimQueueId: Dispatch<SetStateAction<string | undefined>>;
  previousClaims?: Claim[];
  setPreviousClaims: Dispatch<SetStateAction<Claim[] | undefined>>;
  invoiceFiles?: File[];
  setInvoiceFiles: Dispatch<SetStateAction<File[] | undefined>>;
  clinicalHistoryFiles?: File[];
  setClinicalHistoryFiles: Dispatch<SetStateAction<File[] | undefined>>;
  prescriptionFiles?: File[];
  setPrescriptionFiles: Dispatch<SetStateAction<File[] | undefined>>;
  policyDates?: PolicyDates[];
  setPolicyDates: Dispatch<SetStateAction<PolicyDates[] | null>>;
  hasEdited?: boolean;
  setHasEdited: Dispatch<SetStateAction<boolean | undefined>>;
}

export const ClaimContext = createContext({} as ClaimContextProps);
ClaimContext.displayName = 'ClaimContext';

export function ClaimContextProvider({ children }) {
  const [claim, setClaim] = useState<Partial<ClaimForm>>(() => {
    if (isBrowser) {
      const storedClaim = sessionStorage.getItem('claim');
      return storedClaim ? JSON.parse(storedClaim) : undefined;
    }
  });

  const [petDetails, setPetDetails] = useState<Partial<ClaimForm>>(() => {
    if (isBrowser) {
      const storedPetDetails = sessionStorage.getItem('petDetails');
      return storedPetDetails ? JSON.parse(storedPetDetails) : undefined;
    }
  });

  const [claimQueueId, setClaimQueueId] = useState<string>(() => {
    if (isBrowser) {
      const storedClaimQueueId = sessionStorage.getItem('claimQueueId');
      return storedClaimQueueId ? JSON.parse(storedClaimQueueId) : undefined;
    }
  });

  const [previousClaims, setPreviousClaims] = useState<Claim[]>(() => {
    if (isBrowser) {
      const storedPreviousClaims = sessionStorage.getItem('previousClaims');
      return storedPreviousClaims ? JSON.parse(storedPreviousClaims) : undefined;
    }
  });

  const [policyDates, setPolicyDates] = useState<PolicyDates[]>(() => {
    if (isBrowser) {
      const storedPolicyDates = sessionStorage.getItem('policyDates');
      return storedPolicyDates ? JSON.parse(storedPolicyDates) : undefined;
    }
  });

  const [invoiceFiles, setInvoiceFiles] = useState<File[]>([]);
  const [clinicalHistoryFiles, setClinicalHistoryFiles] = useState<File[]>([]);
  const [prescriptionFiles, setPrescriptionFiles] = useState<File[]>([]);

  const [hasEdited, setHasEdited] = useState<boolean>(() => {
    if (isBrowser) {
      const storedHasEdited = sessionStorage.getItem('hasEdited');
      return storedHasEdited ? JSON.parse(storedHasEdited) : undefined;
    }
  }); // Used to track if the user has edited the form  - used for GA tracking

  useEffect(() => {
    // console.log('Claim updated', claim);
    if (claim) {
      sessionStorage.setItem('claim', JSON.stringify(claim));
    } else {
      sessionStorage.removeItem('claim');
    }
  }, [claim]);

  useEffect(() => {
    // console.log('Pet details updated', petDetails);
    if (petDetails) {
      sessionStorage.setItem('petDetails', JSON.stringify(petDetails));
    } else {
      sessionStorage.removeItem('petDetails');
    }
  }, [petDetails]);

  useEffect(() => {
    // console.log('Claim que updated', claimQueueId);
    if (claimQueueId) {
      sessionStorage.setItem('claimQueueId', JSON.stringify(claimQueueId));
    } else {
      sessionStorage.removeItem('claimQueueId');
    }
  }, [claimQueueId]);

  useEffect(() => {
    // console.log('Previous claims updated', previousClaims);
    if (previousClaims) {
      sessionStorage.setItem('previousClaims', JSON.stringify(previousClaims));
    } else {
      sessionStorage.removeItem('previousClaims');
    }
  }, [previousClaims]);

  useEffect(() => {
    // console.log('Policy dates updates', policyDates);
    if (policyDates) {
      sessionStorage.setItem('policyDates', JSON.stringify(policyDates));
    } else {
      sessionStorage.removeItem('policyDates');
    }
  }, [policyDates]);

  useEffect(() => {
    if (hasEdited) {
      sessionStorage.setItem('hasEdited', JSON.stringify(hasEdited));
    } else {
      sessionStorage.removeItem('hasEdited');
    }
  }, [hasEdited]);

  const contextValues: ClaimContextProps = useMemo(
    () => ({
      claim,
      setClaim: setClaim as Dispatch<SetStateAction<Partial<ClaimForm> | undefined>>,
      petDetails,
      setPetDetails: setPetDetails as Dispatch<SetStateAction<ClaimPetDetails | undefined>>,
      claimQueueId,
      setClaimQueueId: setClaimQueueId as Dispatch<SetStateAction<string | undefined>>,
      previousClaims,
      setPreviousClaims,
      policyDates,
      setPolicyDates,
      invoiceFiles,
      setInvoiceFiles,
      prescriptionFiles,
      setPrescriptionFiles,
      clinicalHistoryFiles,
      setClinicalHistoryFiles,
      hasEdited,
      setHasEdited,
    }),
    [
      claim,
      petDetails,
      claimQueueId,
      previousClaims,
      policyDates,
      invoiceFiles,
      prescriptionFiles,
      clinicalHistoryFiles,
      hasEdited,
    ]
  );

  return <ClaimContext.Provider value={contextValues}>{children}</ClaimContext.Provider>;
}
