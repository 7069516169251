import { graphql, useStaticQuery } from 'gatsby';

interface ThemeOptionsProps {
  enableTrustpilot: boolean;
}

export const useThemeOptions = (): ThemeOptionsProps => {
  const themeOptionsData = useStaticQuery(graphql`
    query themeOptionsQuery {
      site {
        siteMetadata {
          enableTrustpilot
        }
      }
    }
  `);
  const returnValues: ThemeOptionsProps = {
    enableTrustpilot: themeOptionsData.site.siteMetadata.enableTrustpilot,
  };

  return returnValues;
};
