import { useContext } from 'react';
import { isBrowser } from '../utils/isBrowser';

interface DataLayerWindow extends Window {
  dataLayer: {
    push: (data: any) => void;
  };
}
declare const window: DataLayerWindow;

interface UseDataLayerPushReturn {
  dataLayerPush: any;
}

export const useDataLayerPush = (): UseDataLayerPushReturn => {
  const dataLayerPush = (data) => {
    if (isBrowser && window.dataLayer) {
      window.dataLayer.push({
        ...data,
      });
    }
  };

  return {
    dataLayerPush,
  };
};
