import axios, { AxiosRequestTransformer } from 'axios';
import { format } from 'date-fns';
import enGb from 'date-fns/locale/en-GB';

export const isStaging = process.env.GATSBY_IS_STAGING;
export const isProd =
  !isStaging &&
  (process.env.NETLIFY || process.env.GATSBY_NETLIFY) &&
  (process.env.BRANCH === 'main' || process.env.GATSBY_BRANCH === 'main');

const dateTransformer: AxiosRequestTransformer = (data) => {
  if (data instanceof Date) {
    return format(new Date(data), 'yyyy-MM-dd', { locale: enGb });
  }
  if (Array.isArray(data)) {
    return data.map((val) => dateTransformer(val));
  }
  if (typeof data === 'object' && data !== null) {
    return Object.fromEntries(Object.entries(data).map(([key, val]) => [key, dateTransformer(val)]));
  }
  return data;
};
// current site hostname
// const getSiteHostname = () => {
//   if (!isBrowser) {
//     return 'localhost';
//   }
//   const { hostname } = window.location;
//   return hostname;
// };

const getApiBaseUrl = () => {
  const baseUrl = isProd ? 'https://proxy-api.agriapet.co.uk/paws' : 'https://proxy-api-test.agriapet.co.uk/paws';

  return baseUrl;
};

export const getClaimsApiBaseUrl = () => {
  const baseUrl = isProd ? 'https://proxy-api.agriapet.co.uk/claims' : 'https://proxy-api-test.agriapet.co.uk/claims';

  return baseUrl;
};

export const accessToken =
  'mKlno4TnhlxlMogC94eW54f2vyaVGhV7UycvufueWucmqD6l3MnvxC=rs=sLUYdM5X7pXbA1h91Vbajlq=427e/JVib8Uq98cu1fB8fTODdyoOeeZIZLZ17U0NIMVrhjUOr5DAdj03tA-jA8IJIb7UN=m0SG0L=l0GD6Rhv?y4zO1OJp2z3q!re3ANFh9l2=TvyS/grBO-9rDsrOkiepLonOSW-VSu-KPeFVwOoZWD3aI-UWhza!cFN2rbp6Linv';
// https://agriapaws-test.agriapet.co.uk/api
export const service = axios.create({
  baseURL: getApiBaseUrl(),
  transformRequest: [dateTransformer].concat(axios.defaults.transformRequest),
  headers: {
    'Content-Type': 'application/json',
    'Access-Token': accessToken,
  },
});

// https://AgriaClaimsApiUAT.agriapet.co.uk
export const claimsService = axios.create({
  baseURL: getClaimsApiBaseUrl(),
  transformRequest: [dateTransformer].concat(axios.defaults.transformRequest),
  headers: {
    'Access-Token': accessToken,
  },
});
