import React, { FC } from 'react';
import styled, { css } from 'styled-components';

interface ContainerProps {
  mobileNoPadding?: boolean;
}

const StyledContainer = styled.div<ContainerProps>`
  ${({ theme: { space, media }, mobileNoPadding }) => css`
    margin: 0 auto;
    padding: 0 ${space.md};
    max-width: 1224px;

    ${mobileNoPadding &&
    css`
      padding: 0;
    `}

    @media (min-width: ${media.sm}) {
      padding: 0 ${space.xxl};
    }
  `};
`;

export const Container: FC<React.PropsWithChildren<ContainerProps>> = ({ children, mobileNoPadding, ...rest }) => (
  <StyledContainer mobileNoPadding={mobileNoPadding} {...rest}>
    {children}
  </StyledContainer>
);
