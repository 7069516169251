import { useState } from 'react';
import { useQuery } from 'react-query';
import { service } from '../client';
import { ApiResponse } from '../types/apis';
import { Customer } from '../types/customer';
import { CoverExtra, CoverOption, Discount, Excess, PaymentScheduleItem, PetEquine } from '../types/quote';
import { dataOrError } from '../utils/dataOrError';

interface GetQuoteResponse {
  AnnualRate: number;
  AvailableCollectionDays: number[];
  CoverExtras: CoverExtra[];
  CoverOptions: CoverOption[];
  Discount: Discount;
  DiscountedRate: number;
  FixedExcesses: Excess[];
  InsurancePremiumTaxPercentage: number;
  InsurancePremiumTaxValue: number;
  IsRenewalQuote: boolean;
  MonthlyRate: number;
  PaymentSchedule: PaymentScheduleItem[];
  QuoteNotificationSent: boolean;
  QuoteReference: string;
  VariableExcesses: Excess[];
  MultipetPercentage: number;
  MultipetAmount: number;
  AffiliateId: string;
  BasketId: string;
  BrandId: string;
  CampaignId: string;
  Customer: Customer;
  Id: string;
  Pet: PetEquine;
  PolicyStartDate: Date;
  ProductId: string;
  PromoCode: string;
  SelectedCoverOptionId: string;
  SelectedExtras: string[];
  SelectedFixedExcessId: string;
  SelectedVariableExcessId: string;
  TermsAgreed: boolean;
}

interface GetQuoteRequest {
  quoteReferenceNumber: string;
  postcode: string;
}
const getQuoteEquine = async ({ quoteReferenceNumber, postcode }: GetQuoteRequest) => {
  const { data } = await service.get<ApiResponse<GetQuoteResponse[]>>(
    `/Quote/GetQuoteEquine?quoteReferenceNumber=${quoteReferenceNumber}&postcode=${postcode}`
  );
  return dataOrError<GetQuoteResponse[]>(data);
};

export function useGetQuoteEquine() {
  const [quoteReferenceNumber, setQuoteReferenceNumber] = useState('');
  const [postcode, setPostcode] = useState('');

  const res = useQuery(
    ['getQuote', quoteReferenceNumber, postcode],
    () => getQuoteEquine({ quoteReferenceNumber, postcode }),
    {
      enabled: !!quoteReferenceNumber,
    }
  );

  const fetchQuote = (newQuoteReferenceNumber, newPostcode) => {
    setQuoteReferenceNumber(newQuoteReferenceNumber);
    setPostcode(newPostcode);
  };

  return {
    ...res,
    fetchQuote,
  };
}
