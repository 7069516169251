import { createGlobalStyle } from 'styled-components';
import { getResponsiveType } from '../utils/getResponsiveType';
import { colors, global, space, shadows, components } from './tokens';

export const GlobalStyle = createGlobalStyle`

    *, *::before, *::after {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        border: 0;
    }

    *:focus {
        outline: none; // We'll roll our own focus
    }

    *:focus-visible {
        outline: solid 2px ${components.global.focusColor};
        outline-offset: 2px; 
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }

    html, body {
        height: 100%;
    }

    body {
        -webkit-font-smoothing: antialiased;

        &.is-hidden {
            overflow: hidden;
        }
    }

    img, picture, video, canvas, svg {
        display: block;
        max-width: 100%;
    }

    input, button, textarea, select {
        font: inherit;
    }

    p, h1, h2, h3, h4, h5, h6 {
        overflow-wrap: break-word;
    }

    #root, #__next {
        isolation: isolate;
    }

    ul, ol {
        list-style: none;
    }

    a {
        color: currentColor;
    }

    a[href^='tel:'] {
        text-decoration: none;
    }

    address {
        font-style: normal;
    }

    button {
        cursor: pointer;
        background: none;
        color: currentColor;
    }

    // Selection

    ::selection {
        // background-color: ${colors.lightPrimary.default};
        // color: ${colors.lightPrimary.contrast};
    }

    // Body

    body {
        ${getResponsiveType('paragraph')}
        background-color: '#fff';
        color: ${components.text.defaultColor};
        min-height: 100vh;
        width: 100%;
        min-width: 330px;
        accent-color: ${colors.lightPrimary.default};
    }

    // Modal

    .ReactModal__Body--open {
        overflow: hidden;
        width: 100%;
        height: 100%;
    }

    // Tippy

    .tippy-popper {
        .tippy-tooltip {
            background: ${colors.primary.default};
            border-radius: ${global.borderRadius.default};
            padding: ${space.sm};
            max-width: 256px;
            box-shadow: ${shadows.default};

            p + p {
              margin-top: ${space.md};
            }
        }

        &[x-placement^=bottom] [x-arrow] {
            border-bottom: 7px solid ${colors.primary.default};
        }

        &[x-placement^=top] [x-arrow] {
            border-top: 7px solid ${colors.primary.default};
        }
    }

    // New tab text link hover

    a.external {
        position: relative;
    }

    a.external #new-tab-text {
        position: absolute;
        left: -9000px;
        width: 0;
        overflow: hidden;
    }

    a.external:hover #new-tab-text {
        position: absolute;
        left: 0;
        border-radius: ${global.borderRadius.default};
        top: calc(100% + 8px);
        background: #fff;
        display: block;
        border: solid 1px;
        padding: ${space.xs} ${space.xxs};
        width: 180px;
        text-align: center;
        box-shadow: ${shadows.default};
        color:${colors.primary.default}; 
    }
`;

export default GlobalStyle;
