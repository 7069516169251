export const colors = {
  lighterPrimary: {
    default: '##E6EAEF',
    contrast: '#02315B',
  },

  lightPrimary: {
    default: '#E6EAEF',
    contrast: '#02315B',
  },

  primary: {
    default: '#02315B',
    contrast: '#FFFFFF',
  },

  darkPrimary: {
    default: '#02315B',
    contrast: '#FFFFFF',
  },
  white: {
    default: '#ffffff',
    contrast: '#02315B',
  },

  red: {
    default: '#E30613',
    contrast: '#FFFFFF',
  },

  midPrimary: {
    default: '#009CDE',
    contrast: '#FFFFFF',
  },

  lightGreen: {
    default: '#B8CCA4',
    contrast: '#FFFFFF',
  },

  green: {
    default: '#228300',
    contrast: '#FFFFFF',
  },

  darkGreen: {
    default: '#3D6615',
    contrast: '#FFFFFF',
  },

  pink: {
    default: '#E68699',
    contrast: '#FFFFFF',
  },

  orange: {
    default: '#E07800',
    contrast: '#FFFFFF',
  },

  yellow: {
    default: '#F2A900',
    contrast: '#FFFFFF',
  },

  grey: {
    default: '#EBF4F9',
    contrast: '#02315B',
  },

  lightGrey: {
    default: '#EBF4F9',
    contrast: '#02315B',
  },

  transparent: {
    default: 'transparent',
    contrast: 'transparent',
  },
};

export default colors;

export type ThemeColors = typeof colors;
export type ThemeColorsKeys = keyof typeof colors;
